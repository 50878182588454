import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import BlockContent from "@sanity/block-content-to-react";
import serializers from "../helper/TextSerializer";
import PageHero from "../components/page-hero";
import SEO from "../components/seo";
import Fader from "../helper/Fader";

export const query = graphql`
  {
    allSanityResource(sort: { fields: order, order: ASC }) {
      nodes {
        id
        order
        title
        _rawBody(resolveReferences: { maxDepth: 8 })
      }
    }
    allSanityPage(filter: { slug: { current: { eq: "online-resources" } } }) {
      edges {
        node {
          id
          pageSEO {
            metaDescription
            metaImage {
              asset {
                url
              }
            }
            metaTitle
          }
          title
          _rawIntro(resolveReferences: { maxDepth: 8 })
        }
      }
    }
  }
`;

class OnlineResources extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = { resourceId: this.props.data.allSanityResource.nodes[0].id };
  }

  handleClick(e, id) {
    e.preventDefault();
    let container = document.getElementsByClassName("page-body-inner")[0];
    container.classList.add("-hidden");
    setTimeout(() => {
      container.classList.remove("-hidden");
      this.setState({
        resourceId: id,
      });
    }, 500);
  }

  render() {
    const resources = this.props.data.allSanityResource.nodes;
    const page = this.props.data.allSanityPage.edges[0].node;

    return (
      <Layout className="page-archive-container">
        <SEO
          title={(page.pageSEO && page.pageSEO.metaTitle) || page.title}
          description={page.pageSEO && page.pageSEO.metaDescription}
          image={page.pageSEO && page.pageSEO.metaImage}
        />
        <PageHero
          title={page.title}
          intro={page._rawIntro}
          content={
            <div className="resource-nav">
              {/* MOBILE */}
              <select
                title="Category Selector"
                className="category-selector"
                value={this.state.resourceId}
                onChange={e => this.handleClick(e, e.target.value)}
              >
                {resources.map((resource, i) => {
                  return (
                    <option key={i} value={resource.id}>
                      {resource.title}
                    </option>
                  );
                })}
              </select>
              {/* DESKTOP */}
              <div className="resource-nav-inner">
                {resources.map((resource, i) => {
                  return (
                    <a
                      key={i}
                      className={
                        this.state.resourceId === resource.id
                          ? "resource-button -active"
                          : "resource-button"
                      }
                      href="#"
                      onClick={e => this.handleClick(e, resource.id)}
                    >
                      {resource.title}
                    </a>
                  );
                })}
              </div>
            </div>
          }
        />

        <Fader>
          <div className="archive-content page-body">
            <div className="page-body-inner">
              {resources
                .filter(resource => resource.id === this.state.resourceId)
                .map((resource, i) => {
                  return (
                    <BlockContent
                      key={i}
                      blocks={resource._rawBody.textBlock}
                      serializers={{
                        types: {
                          block: serializers.types.block,
                          undefined: serializers.undefined,
                          image: serializers.image,
                        },
                        marks: serializers.marks,
                      }}
                    />
                  );
                })}
            </div>
          </div>
        </Fader>
      </Layout>
    );
  }
}

export default OnlineResources;
